/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate, Link, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { toast } from 'react-toastify';

import {
	ConnectingAirportsOutlined,
	ConstructionOutlined,
	Done as DoneIcon
} from '@mui/icons-material';

import {
	Checkbox,
	CircularProgress,
	TextField,
	Button,
	Paper,
	Typography,
	TableHead,
	TableRow,
	TableContainer,
	TableCell,
	TableBody,
	Table,
} from '@mui/material';

const hrefStyle = {
	color: "#FF8444",
	fontSize: "14px",
	textDecoration: "none",
	display: "flex",
}

const googleLoginIcon = {
	boxSizing: "border-box",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "16px",
	gap: "8px",
	// position: "sticky",
	width: 171,
	height: "51px",
	left: "352px",
	top: "725px",
	background: "#1A1A1A",
	border: "1px solid #494949",
	borderRadius: "8px",

}

const githubLoginIcon = {
	boxSizing: "border-box",
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	padding: "16px",
	gap: "8px",
	position: "sticky",
	width: "173px",
	height: "51px",
	left: "539px",
	top: "725px",
	backgroundColor: "#1A1A1A",
	border: "1px solid #494949",
	borderRadius: "8px",
}

const surfaceColor = "#27292D"
const inputColor = "#383B40"

const useStyles = makeStyles({
	notchedOutline: {
		borderColor: "#f85a3e !important"
	},
});

const LoginPage = props => {
	const { globalUrl, isLoaded, isLoggedIn, setIsLoggedIn, setCookie, inregister, serverside } = props;
	let navigate = useNavigate();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [message, setMessage] = useState("");
	const [loginLoading, setLoginLoading] = useState(false);

	const [MFAField, setMFAField] = useState(false);
	const [MFAValue, setMFAValue] = useState("");
	const [register, setRegister] = useState(inregister);
	const [checkboxClicked, setCheckboxClicked] = useState(false);
	const [loginWithSSO, setLoginWithSSO] = useState(false)

	const parsedsearch = serverside === true ? "" : window.location.search
	if (serverside !== true) {
		const tmpMessage = new URLSearchParams(window.location.search).get("message")
		if (tmpMessage !== undefined && tmpMessage !== null && message !== tmpMessage) {
			setMessage(tmpMessage)
		}
	}

	if (document !== undefined) {
		if (register) {
        	document.title = "Login to Shuffle SaaS"
		} else {
        	document.title = "Register to Shuffle SaaS"
		}
	}

	// Just a way to force location loading properly
	// Register & login should be split :3
	if (window !== undefined) {
		const path = window.location.pathname;
		if (path.includes("/login") && register === false) {
			setRegister(!register)
		} else if (path.includes("/register") && register === true) {
			setRegister(!register)
		} else {
			//console.log("Path: " + path, "Register: " + register)
		}
	}

	const bodyDivStyle = {
		marginTop: 100,
		width: isMobile ? "100%" : 500,
		background: "#1A1A1A",
		margin: "auto",
		display: isMobile ? "" : "flex",
		paddingTop: 20,
		overflow: "hidden",
		paddingRight: isMobile ? "" : register ? 570 : 285, // The fuck is this please look at this only
	}
	const boxStyle = {
		color: "white",
		paddingLeft: "30px",
		paddingRight: "30px",
		paddingBottom: "76px",
		paddingTop: "30px",
		marginBottom: 150,
	}

	const paperStyleReg = {
		width: 300,
		height: 350,
		background: "#212121",
		borderRadius: "8px",
		marginLeft: isMobile ? 80 : register ? "455px" : "485px",
		marginTop: isMobile ? 10 : 110,
		position: isMobile ? "" : "absolute",

	}
	const paperStyleLog = {
		position: "absolute",
		width: isMobile ? "400px" : "532px",
		padding: "0px 30px 0px",
		// marginTop: "135px",
		// background: "#212121",
		borderRadius: "8px",
	}
	const createData = (icon, title) => {
		return {
			icon,
			title,
		}
	}

	// Used to swap from login to register. True = login, false = register
	const activeIcon = <DoneIcon style={{ color: "green" }} />
	const rows = [
		createData(activeIcon, "Access to All Apps"),
		createData(activeIcon, "10,000 App Runs"),
		createData(activeIcon, "Monthly Runs Refresh"),
		createData(activeIcon, "Unlimited Users & Workflows"),
		createData(activeIcon, "Multi-Tenancy & -Region"),
		createData(activeIcon, "Support & Discord Access"),
	];
	const classes = useStyles();
	// Error messages etc
	const [loginInfo, setLoginInfo] = useState("");

	const handleValidateForm = (username, password) => {
		if (loginWithSSO) {
			return username.length > 1
		}
		return (username.length > 1 && password.length > 8);
	}

	if (isLoggedIn === true && serverside !== true) {
		const tmpView = new URLSearchParams(window.location.search).get("view")
		if (tmpView !== undefined && tmpView !== null && tmpView === "pricing") {
			window.location.pathname = "/pricing"
			return
		} else if (tmpView !== undefined && tmpView !== null) {
			window.location.pathname = tmpView
			return
		}

		window.location.pathname = "/workflows"
	}

	const onSubmit = (e) => {
		//toast("Testing from login page")

		setMessage("")
		setLoginLoading(true)
		e.preventDefault()

		// Just use this one?
		var data = { "username": username, "password": password }
		if (MFAValue !== undefined && MFAValue !== null && MFAValue.length > 0) {
			data["mfa_code"] = MFAValue
		}

		localStorage.setItem("globalUrl", "")

		var baseurl = globalUrl
		if (register) {
			var url = baseurl + '/api/v1/login';

			if (loginWithSSO === true) {
				url = baseurl + '/api/v1/login/sso'
				setLoginInfo("Logging in with SSO. Please wait while we find a relevant org...")
			}

			fetch(url, {
				mode: 'cors',
				method: 'POST',
				body: JSON.stringify(data),
				credentials: 'include',
				crossDomain: true,
				withCredentials: true,
				headers: {
					'Content-Type': 'application/json; charset=utf-8',
				},
			})
				.then((response) => {
					if (response.status !== 200) {
						console.log("Status not 200 for login:O!");
					}

					return response.json();
				})
				.then((responseJson) => {

					setLoginLoading(false)

					console.log("Resp from backend: ", responseJson)

					if (responseJson["success"] === false) {
						setLoginInfo(responseJson["reason"])
					}
					else {

						if (responseJson["reason"] === "MFA_REDIRECT") {
							setLoginInfo("MFA required. Please input the 6-digit code from your authenticator app of choice (e.g. Google or Microsoft)")
							setMFAField(true)
							return

						}
						else if (responseJson["reason"] === "MFA_SETUP") {
							window.location.href = `/login/${responseJson.url}/mfa-setup`;
							return;
						}
						else if (responseJson["reason"] === "SSO_REDIRECT") {
							//navigate(responseJson["url"])
							window.location.href = responseJson["url"]
							return

						}
						else if (responseJson["reason"] !== undefined && responseJson["reason"] !== null && responseJson["reason"].includes("error")) {
							setLoginInfo(responseJson["reason"])
							return
						}


						setLoginInfo("Successful login! Redirecting you in 3 seconds...")
						for (var key in responseJson["cookies"]) {
							setCookie(responseJson["cookies"][key].key, responseJson["cookies"][key].value, { path: "/" })
						}

						const tmpView = new URLSearchParams(window.location.search).get("view")
						if (tmpView !== undefined && tmpView !== null) {
							//const newUrl = `/${tmpView}${decodeURIComponent(window.location.search)}`
							// Check if slash in the url

							var newUrl = `/${tmpView}`
							if (tmpView.startsWith("/")) {
								newUrl = `${tmpView}`
							}

							console.log("Found url: ", newUrl)

							window.location.pathname = newUrl
							return
						}

						console.log("LOGIN DATA: ", responseJson)
						if (responseJson.tutorials !== undefined && responseJson.tutorials !== null) {
							// Find welcome in responseJson.tutorials under key name
							const welcome = responseJson.tutorials.find(function (element) {
								return element.name === "welcome";
							})

							console.log("Welcome: ", welcome)
							if (welcome === undefined || welcome === null) {
								console.log("RUN login Welcome!!")
								//window.location.pathname = "/welcome?tab=2"
								//window.location = "/welcome?tab=2"
								window.location.href = "/welcome?tab=2"
								return
							}
						}

						window.location.pathname = "/workflows"
					}
				})
				.catch(error => {
					setLoginInfo("Error from login API: " + error)
					setLoginLoading(false)
				});
		} else {
			url = baseurl + '/api/v1/register';
			fetch(url, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then(response =>
					response.json().then(responseJson => {
						if (responseJson["success"] === false) {
							setLoginInfo(responseJson["reason"])
						} else {
							if (responseJson["reason"] === "shuffle_account") {
								window.location.href = "/login?message=Please+login+with+your+Shuffle+account"
								return
							}

							//setLoginInfo("Successful register!")
							//var newpath = "/login?message=Successfully signed up. You can now sign in."
							//const tmpMessage = new URLSearchParams(window.location.search).get("message")
							setLoginInfo("Successful registration! Redirecting in 3 seconds...")
							for (var key in responseJson["cookies"]) {
								setCookie(responseJson["cookies"][key].key, responseJson["cookies"][key].value, { path: "/" })
							}

							setTimeout(() => {
								console.log("LOGIN DATA: ", responseJson)

								const tmpView = new URLSearchParams(window.location.search).get("view")
								if (tmpView !== undefined && tmpView !== null) {
									//const newUrl = `/${tmpView}${decodeURIComponent(window.location.search)}`
									const newUrl = `/${tmpView}`
									window.location.pathname = newUrl
									return
								}

								//if (responseJson.tutorials === undefined || responseJson.tutorials === null || !responseJson.tutorials.includes("welcome")) {
								console.log("RUN Welcome!!")
								//window.location.pathname = "/welcome?tab=2"
								window.location.href = "/welcome?tab=2"
							}, 1500);
						}
						setLoginLoading(false)
					}),
				)
				.catch(error => {
					setLoginInfo("Error in login. Please try again, or contact support@shuffler.io if the problem persists.")
					setLoginLoading(false)
				});
		}
	}

	const onChangeUser = (e) => {
		setUsername(e.target.value)
	}

	const onChangePass = (e) => {
		setPassword(e.target.value)
	}

	const HandleLoginWithSSO = () => {
		setPassword("")
		setLoginInfo("")
		setLoginWithSSO(true)
	}

	//const onClickRegister = () => {
	//	if (props.location.pathname === "/login") {
	//		window.location.pathname = "/register"	
	//	} else {
	//		window.location.pathname = "/login"	
	//	}

	//	setLoginCheck(!register)
	//}

	//var loginChange = register ? (<div><p onClick={setLoginCheck(false)}>Want to register? Click here.</p></div>) : (<div><p onClick={setLoginCheck(true)}>Go back to login? Click here.</p></div>);
	var formtitle = register ? <div>Welcome Back!</div> : <div>Create your account</div>
	var formButton = register ? <div style={{ display: "flex" }}> <div style={{ fontSize: "14px", paddingRight: "7px", textDecoration: "none", }}>Don’t have an account yet?</div> <Link style={hrefStyle} to={`/register${parsedsearch}`}><div>Register here</div></Link></div> : <></>
	//<Link to={`/login${parsedsearch}`} style={hrefStyle}><div>Click here to Login</div></Link>

	//	<DialogTitle>{formtitle}</DialogTitle>

	const buttonBackground = "linear-gradient(89.83deg, #FF8444 0.13%, #F2643B 99.84%)"

	const buttonStyle = { borderRadius: 25, height: 50, fontSize: 18, backgroundImage: handleValidateForm(username, password) || loginLoading || (checkboxClicked && register) ? buttonBackground : "grey", color: "white" }
	//<Button variant="contained" type="submit" fullWidth style={buttonStyle} disabled={!handleValidateForm() || loginLoading}>
	const basedata =
		<div style={bodyDivStyle}>
			<Paper style={boxStyle}>
				<form onSubmit={onSubmit} style={{ margin: 15, }}>
					<img style={{ height: 44, width: isMobile ? "100%" : 44, paddingBottom: isMobile ? null : 40 }} src="images/logos/orange_logo.svg" />

					<Typography color="textSecondary" style={{ textAlign: isMobile ? "center" : null, marginTop: 10, marginBottom: 10, }}>{message}</Typography>

					<h2 style={{ marginBottom: 2, textAlign: isMobile ? "center" : null, }}>{formtitle}</h2>

					{register ? <Typography color="textSecondary" style={{ marginBottom: 28, textAlign: isMobile ? "center" : null, }} variant="body2">Find new ways to automate by discovering usecases made by Shufflers</Typography> : <Typography color="textSecondary" style={{ marginBottom: 10, textAlign: isMobile ? "center" : null, }} variant="body2">Please fill in the information to continue.</Typography>}
					Email
					<div style={{ marginBottom: 5 }}>
						<TextField
							color="primary"
							style={{ backgroundColor: inputColor, marginTop: 5, minWidth: isMobile ? null : 360, }}
							autoFocus
							InputProps={{
								classes: {
									notchedOutline: classes.notchedOutline,
								},
								style: {
									height: "50px",
									color: "white",
									fontSize: "1em",
								},
							}}
							required
							fullWidth={true}
							autoComplete="username"
							placeholder="username@example.com"
							id="emailfield"
							margin="normal"
							variant="outlined"
							onChange={onChangeUser}
						/>
					</div>
					{!loginWithSSO && (
						<div>
							Password
							<div>
								<TextField
									color="primary"
									style={{ backgroundColor: inputColor, marginTop: 5, }}
									InputProps={{
										classes: {
											notchedOutline: classes.notchedOutline,
										},
										style: {
											height: "50px",
											color: "white",
											fontSize: "1em",
										},
									}}
									required
									id="outlined-password-input"
									fullWidth={true}
									type="password"
									autoComplete="current-password"
									placeholder="at least 10 characters"
									margin="normal"
									variant="outlined"
									onChange={onChangePass}
									helperText={handleValidateForm(username, password) ? "" : "Password must be at least 9 characters long"}
								/>
							</div>
						</div>
					)}
					{MFAField === true ?
						<div style={{ marginTop: 15 }}>
							2-factor code
							<TextField
								autoFocus
								color="primary"
								style={{ backgroundColor: inputColor, marginTop: 5, }}
								InputProps={{
									classes: {
										notchedOutline: classes.notchedOutline,
									},
									style: {
										height: "50px",
										color: "white",
										fontSize: "1em",
									},
								}}
								required
								id="outlined-password-input"
								fullWidth={true}
								type="text"
								placeholder="6-digit code"
								margin="normal"
								variant="outlined"
								onChange={(event) => {
									setMFAValue(event.target.value)
								}}
							/>
						</div>
						: null}
					<div style={{ display: "flex", flexDirection: "column", marginTop: "0px" }}>
						<div>
							{register && !loginWithSSO ? <Link to={`/passwordreset${parsedsearch}`} style={hrefStyle}><div style={{ paddingLeft: isMobile ? null : "290px", }}>Forgot password?</div></Link> : <></>}
						</div>
					</div>
					{register ? <></> : <Typography color="textSecondary" style={{ marginTop: 5 }} variant="body2">
						<Checkbox style={{ color: "#F85A3E" }} onChange={(event) => {
							setCheckboxClicked(!checkboxClicked)
						}}></Checkbox>
						I agree to <a href="https://shuffler.io/docs/terms_of_service" style={{ textDecoration: "none", color: "#f85a3e" }} rel="noopener noreferrer" target="_blank">Shuffle's Terms of Service</a>.
					</Typography>}
					<div style={{ display: "flex", marginTop: "15px", paddingBottom: register ? "40px" : "" }}>
						<Button variant="contained" type="submit" fullWidth style={buttonStyle} disabled={!handleValidateForm(username, password) || loginLoading || (!checkboxClicked && !register)}>
							{loginLoading ? <CircularProgress color="secondary" style={{ color: "white", }} /> : "Continue"}
						</Button>
					</div>

					<div style={{ display: "flex", flexDirection: "column", marginTop: "0px", alignItems: isMobile ? "center" : null }}>
						<div flex={{ flex: 1 }}>
							{formButton}
						</div>
					</div>
					<div style={{ marginTop: "10px" }}>
						{loginInfo}
					</div>

					{/*
					<div>
						{!loginWithSSO && register ? (
							<div style={{ width: '100%', textAlign: 'center', marginTop: 25}}>
								Or
								<Button 
									variant="outlined"
									style={{ textTransform: 'none', width: '100%', marginTop: 10, borderRadius: 25, height: 40, fontSize: 16 }} 
								onClick={HandleLoginWithSSO}>
									Login With SSO
								</Button>
							</div>
						) : (
							<>{register && (<Button variant="outlined" onClick={() => { setLoginWithSSO(false); setLoginInfo("") }} style={{ textTransform: 'none', marginTop: 10, borderRadius: 10, width: 100, height: 40, fontSize: 18 }}>Back</Button>)}</>
						)}
					</div>
					*/}
				</form>
				{/*

				<div style={{ display: "flex", textAlign: "center", padding: "20px", alignItems: "center" }}>
					<div style={{ border: "1px solid #494949", marginTop: "15px", width: "157px", textAlign: "center", borderRadius: "5px", transform: "rotate(180deg)" }}></div>
					<div style={{ color: "#9E9E9E", margin: "auto" }}>or</div>
					<div style={{ border: "1px solid #494949", marginTop: "15px", width: "157px", textAlign: "center", borderRadius: "5px", transform: "rotate(180deg)" }}></div>
				</div>

				<div style={{ display: "flex", paddingLeft: "40px",paddingRight: "40px" }}>
					<div style={googleLoginIcon}>
						<img src="images/social/original/googlelogin.png" style={{ width: "24px", height: "24.49px" }} />
					</div>
					<div style={githubLoginIcon}>
						<img src="images/social/original/githublogin.png" style={{ width: "24px", height: "24.49px" }} />
					</div>
				</div>

				*/}
			</Paper>
			{isMobile ? "" : register ?
				<Paper>
					<img style={paperStyleLog} src="images/loginsideimage.png" />
				</Paper>
				:
				<Paper style={paperStyleReg}>
					<div style={{ padding: "30px" }}>
						<Typography color="textPrimary" variant="h8">
							The free plan includes:
						</Typography>
					</div>
					{rows.map((row, index) => (
						<div style={{ display: "flex", paddingLeft: "30px" }}>
							<div style={{ paddingRight: "10px" }}>
								<Typography color="textPrimary">
									{row.icon}
								</Typography>
							</div>
							<div>
								<Typography color="textPrimary" variant="h10" style={{}}>
									{row.title}
								</Typography>
							</div>
						</div>
					))}
				</Paper>
			}
		</div>

	const loadedCheck = isLoaded ?
		<div>
			{basedata}
		</div>
		:
		<div>
		</div>

	return (
		<div>
			{loadedCheck}
		</div>
	)
}

export default LoginPage;
